import { useCallback } from 'react';

import { BookDefinition, BookMonopoly } from '@/components/common/Book';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { SectionLayoutType, SelectionItem } from '@/models/backendsApi/v2/Views/ViewsType';

import { GenreHome3InfoPreset, GenreHomeBookItemRenderer } from '../../common/GenreHomeBook';
import { ListLayoutSection } from '../../common/ListLayoutSection';
import { SectionLayout } from '../../common/SectionLayout';

export interface SelectionProps {
  section: SectionByLayout<SectionLayoutType.Selection>;
}

export const Selection = ({ section }: SelectionProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();

  const render = useCallback(
    ({ item, index }: { item: BookViewItem<SelectionItem>; index: number }) => (
      <GenreHomeBookItemRenderer item={item} index={index} />
    ),
    [],
  );

  // TODO: [선독점 노출 강조] MonopolyBadge 커스텀 필요 여부 확인
  const presets = BookMonopoly.useMonopolyBadgePresets(GenreHome3InfoPreset);

  return (
    <BookDefinition presets={presets} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout title={section.title} link={section.more_details?.path} paddingBottom={false}>
        <ListLayoutSection items={section.items} renderItem={render} />
      </SectionLayout>
    </BookDefinition>
  );
};
